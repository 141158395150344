<template>
  <div class="block">
    <p class="title">{{ name }}</p>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name'],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
.block {
  background: $secondary-color;
  .title {
    line-height: 50px;
    color: #eeeeee;
    font-size: 18px;
    border: 1px solid $boder-color;
    border-left: none;
    border-right: none;
    padding-left: 20px;
  }
  .content {
    padding: 15px;
  }
}
</style>
