import { Shape } from '@antv/x6';
import $interfaces from '@/axios/interfaces';
import green from '../image/green.png';
import grey from '../image/grey.png';
import orange from '../image/orange.png';
import red from '../image/red.png';

// 状态灯基于设备模型的缩放倍数
const scale_base = 0.3;
const status_map = {
  RUNNING_STATE: green,
  ALARM_STATE: red,
  FREE_STATE: orange,
  OFFLINE_STATE: grey
};

export default async cells => {
  if (cells.length) {
    const deviceIds = cells.map(cell => (cell.data && cell.data.deviceId ? cell.data.deviceId : '')).filter(cell => cell);
    if (deviceIds.length) {
      const devices = await $interfaces.electron.deviceRunningStatus({ deviceIds });
      const finalCells = cells
        .filter(cell => (cell.data && cell.data.deviceId ? cell.data.deviceId : ''))
        .map(cell => {
          cell.data = {
            ...cell.data,
            runningState: (() => {
              const status = devices.filter(device => device.deviceId === cell.data.deviceId)[0].runningState;
              return status_map[status];
            })()
          };
          return cell;
        });
      return finalCells.forEach(cell => {
        const { shape, data } = cell;
        if (shape === 'image') {
          const { width, height } = cell.getSize();
          const statusNodeWidth = width * scale_base;
          const statusNodeHeight = height * scale_base;

          const imageNode = new Shape.Image({
            width: statusNodeWidth,
            height: statusNodeHeight,
            imageUrl: data.runningState
          });

          cell.addChild(imageNode);
          imageNode.position(width - statusNodeWidth / 2, -statusNodeHeight, { relative: true });
        }
      });
    }
  }
};
