<template>
  <div class="drawer">
    <el-drawer :size="419" :visible.sync="visible" direction="ltr" :modal="false" :wrapperClosable="false">
      <oee ref="oee" />
      <status class="status" ref="status" />
      <warning ref="warning" />
    </el-drawer>
  </div>
</template>

<script>
import oee from './oee';
import status from './status';
import warning from './warning';
export default {
  data() {
    return {
      visible: false
    };
  },
  methods: {
    changeVisible() {
      this.visible = true;
      setTimeout(() => {
        this.loadData();
      }, 300);
    },
    loadData() {
      this.$refs.oee.loadData();
      this.$refs.status.interval();
      this.$refs.warning.interval();
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        clearInterval(window.namespace.intervals.map.status);
        clearInterval(window.namespace.intervals.map.warn);
      }
    }
  },
  components: {
    oee,
    warning,
    status
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
/deep/ {
  .el-drawer {
    width: 420px !important;
    color: #fff;
    box-shadow: none;
    background: $secondary-color;
    .el-drawer__header {
      color: #fff;
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}
/deep/ .el-drawer__body::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/deep/ .el-drawer__body::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/deep/ .el-drawer__body {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}
</style>
