<template>
  <div class="status" v-loading="loading">
    <block name="设备状态">
      <ul class="status-list">
        <li class="status-item" v-for="status in status_list" :key="status.color">
          <div class="icon-wrap">
            <i class="iconfont" :class="status.iconfont"></i>
          </div>
          <div class="info-content">
            <div class="info-status">
              <div class="color" :style="{ background: status.color }"></div>
              <p class="name">{{ status.name }}</p>
            </div>
            <p class="info-num">{{ status.num }}</p>
          </div>
        </li>
      </ul>
    </block>
  </div>
</template>

<script>
import block from './block.vue';
import { polling_time } from '../config';
export default {
  data() {
    return {
      status_list: [],
      loading: false
    };
  },
  methods: {
    async loadData() {
      this.loading = true;

      const factoryId = JSON.parse(localStorage.getItem('FactoryMap')).id;
      const data = await this.$apis.electron.deviceRunningCount({ factoryId });
      const map = {
        runningDeviceSize: {
          iconfont: 'icon-dcyunhangshichang',
          color: '#67C23A',
          name: '运行',
          num: 0
        },
        freeDeviceSize: {
          iconfont: 'icon-dckongxianshichang',
          color: '#F39800',
          name: '空闲',
          num: 0
        },
        alarmDeviceSize: {
          iconfont: 'icon-dcbaojingshichang',
          color: '#FF5050',
          name: '报警',
          num: 0
        },
        offlineDeviceSize: {
          iconfont: 'icon-dcyunhangshichang',
          color: '#909399',
          name: '离线',
          num: 0
        }
      };

      for (const key in data) {
        if (map[key]) {
          map[key].num = data[key];
        }
      }

      this.status_list = map;
      this.loading = false;
    },
    interval() {
      this.loadData();
      // 清理上一次的定时器
      this.clearInterval();

      const interId = setInterval(() => {
        this.loadData();
      }, polling_time);

      window.intervals.push(interId);
      window.namespace.intervals.map.status = interId;
    },
    clearInterval() {
      clearInterval(window.namespace.intervals.map.status);
    }
  },
  components: {
    block
  }
};
</script>

<style lang="scss" scoped>
.status {
  background: #091227;
  .status-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .status-item {
      width: 50%;
      height: 50px;
      display: flex;
      justify-content: center;
      &:nth-child(1) {
        margin-bottom: 30px;
      }
      .icon-wrap {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #0f0f0f;
        border-radius: 4px;

        .iconfont {
          font-size: 25px;
        }
      }
      .info-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 20px;
        .info-status {
          display: flex;
          align-items: center;
          .color {
            width: 14px;
            height: 14px;
            margin-right: 12px;
          }
          .name {
            color: #dbdbdb;
            font-size: 14px;
          }
        }
        .info-num {
          color: #fff;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
