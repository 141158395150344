<template>
  <div class="warning" v-loading="loading" style="max-height: 200px">
    <block name="设备报警信息">
      <Vtable :column="column" :data="data" />
    </block>
  </div>
</template>

<script>
import block from './block.vue';
import Vtable from './table';
import { polling_time } from '../config';
export default {
  data() {
    return {
      loading: false,
      column: [
        {
          prop: 'deviceName',
          label: '设备名称'
        },
        {
          prop: 'name',
          label: '报警内容'
        },
        {
          prop: 'exceptionTime',
          label: '报警时间'
        }
      ],
      data: []
    };
  },
  methods: {
    async loadData() {
      this.loading = true;

      const factoryId = JSON.parse(localStorage.getItem('FactoryMap')).id;
      this.data = await this.$apis.electron.alarmException({ factoryId });

      this.loading = false;
    },
    interval() {
      this.loadData();
      // 清理上一次的定时器
      this.clearInterval();

      const interId = setInterval(() => {
        this.loadData();
      }, polling_time);

      window.intervals.push(interId);
      window.namespace.intervals.map.warn = interId;
    },
    clearInterval() {
      clearInterval(window.namespace.intervals.map.warn);
    }
  },
  components: {
    block,
    Vtable
  }
};
</script>

<style lang="scss" scoped></style>
