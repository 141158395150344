import { Graph } from '@antv/x6';

export default (container, options) =>
  new Graph({
    container,
    // 启用滚轮缩放画布
    mousewheel: {
      enabled: true,
      modifiers: ['ctrl', 'meta']
    },
    ...options
  });
