<template>
  <div class="table">
    <el-table :data="data" style="width: 100%" height="300px">
      <el-table-column align="left" :prop="item.prop" :label="item.label" :width="item.width" v-for="item in column" :key="item.prop"> </el-table-column>
      <el-table-column label="操作" :width="operations.width" v-if="operations">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="iconfont"
            :class="handler.icon"
            @click="handler.handler(scope.$index, scope.row)"
            v-for="handler in operations.handlers"
            :key="handler.icon"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ['column', 'data', 'operations'],
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
/deep/ .el-table {
  &::before {
    background: #060606;
  }
  thead {
    th.el-table__cell {
      background-color: $table__cell;
      color: #f3f3f3;
      border: 0;
    }
  }
  tr {
    background-color: $auxiliary-color;
    color: #dbdcdf;
  }
  .el-table__cell {
    border-color: $secondary-color;
    border-width: 3px;
    padding: 8px 0;
  }
  .el-table__row {
    &:hover {
      td.el-table__cell {
        background-color: $auxiliary-color !important;
      }
    }
  }
  .el-table__empty-block,
  .el-table__body-wrapper {
    background: $secondary-color;
  }
}
.iconfont {
  font-size: 16px;
  color: #5a5a5a;
  &:hover {
    color: $--color-primary;
  }
}
</style>
