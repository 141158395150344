<template>
  <div class="oee" v-loading="loading">
    <block :name="name">
      <ul class="list">
        <li class="item" v-for="item in list" :key="item.color">
          <el-progress :width="80" :stroke-width="5" type="circle" :percentage="item.value" :color="item.color"></el-progress>
          <p class="name">{{ item.name }}</p>
        </li>
      </ul>
    </block>
  </div>
</template>

<script>
import block from './block.vue';
export default {
  data() {
    return {
      loading: false,
      name: '',
      list: [
        {
          value: 0,
          name: '可用率',
          color: '#68A8E9'
        },
        {
          value: 0,
          name: '表现指数',
          color: '#F39800'
        },
        {
          value: 0,
          name: '质量指数',
          color: '#67C23A'
        }
      ]
    };
  },
  methods: {
    async loadData() {
      this.loading = true;

      const factoryId = JSON.parse(localStorage.getItem('FactoryMap')).id;
      const data = await this.$apis.electron.devicePerformanceSummaryReport({ factoryId });

      if (data) {
        const { oee, quality, performance, availability } = data;
        this.name = `近 7 天平均OEE ${oee}%`;
        this.list[0].value = Number(availability);
        this.list[1].value = Number(performance);
        this.list[2].value = Number(quality);
      } else {
        this.name = `近 7 天平均OEE 0%`;
        this.list[0].value = 0;
        this.list[1].value = 0;
        this.list[2].value = 0;
      }
      this.loading = false;
    }
  },
  components: {
    block
  }
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss';
.oee {
  /deep/ .block {
    .title {
      border-top: none;
    }
  }
  .list {
    color: #dbdcdf;
    display: flex;
    justify-content: space-around;
    .item {
      text-align: center;
      .name {
        margin-top: 13px;
      }
    }
  }
}
/deep/ .el-progress {
  .el-progress-circle__track {
    stroke: $boder-color;
  }
  .el-progress__text {
    color: #f3f3f3;
    font-size: 15px !important;
  }
}
</style>
